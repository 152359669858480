@import "../../../../assets/scss/common/variables";
@import "../../../../assets/scss/common/mixins";

.email_registration {

  .content__inner--editor {
    margin: 0 0 32px;
  }

  .email_registration_modal_header {
    font-weight: bold;
    border-bottom: 1px solid black;
  }

  .email_registration_modal_body {
    padding-top: 12px;
  }

  .email_registration_content {
    font-size: 14px;
  }

  .base_tab_wrap {
    width: 100%;
  }

  .base_tab {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    user-select: none;
    @include mq-l {
      flex-wrap: wrap;
    }
    @include mq-s {
      flex-wrap: wrap;
    }
  }

  .base_tab_item {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 162px;
    flex-direction: column;
    list-style: none;
    font-weight: bold;
    box-sizing: border-box;

    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .base_tab_item__link {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

    cursor: pointer;

    background-color: white;
    color: black;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .base_tab_item_title {
    font-size: 20px;
  }

  .base_tab_item_sub_title {
    text-align: center;
    font-size: 0.1vw;
  }

  .base_tab_content {
    background-color: #f0f2f4;
    padding: 16px;
  }


}
